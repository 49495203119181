import logo from './logo.svg';
import './App.css';
import { useState } from 'react'

function App() {

  const [counter, setCounter] = useState(0)

  function handleCounter(){
    setCounter(counter + 1)
  }

  return (
    <div className='container'>
      <ul className="header">
        <li>header</li>
        <li>item 01</li>
        <li>item 02</li>
        <li>item 03</li>
        <li>item 04</li>
        <li>{counter}</li>
      </ul>
      <div className='increase' onClick={handleCounter}>Increase Number</div>
    </div>
  );
}

export default App;
